<template>
  <b-card title="Warranty Registration">
    
    <!-- <b-modal
      id="promptDownload"
      ref="modal"
      v-model="promptDownload"
      title="Download"
      hide-footer
    >
    <strong>Select download file type</strong>
    <div style="display: table; margin: 0 auto;">
      <download-excel :data="warranties" :fields="filter" name="RSP Warranty.xls" class="download-border">
        <img src="@/assets/images/xls.png" height=100>
      </download-excel>
      <download-excel :data="warranties" :fields="filter" type="csv" name="RSP Warranty.csv" class="download-border">
        <img src="@/assets/images/csv.png" height=100>
      </download-excel>
    </div>
    </b-modal> -->

    <b-modal
      id="promptDownload"
      ref="modal"
      v-model="promptDownload"
      title="Download"
      hide-footer
    >
    <strong v-if="warrantyToDownload.length<metadata.totalItem">Generating Data to Download...</strong>
    <strong v-else>Loading data finished, total data is {{warrantyToDownload.length}}</strong>
    <div>
      <strong> Progress {{ progressDownload }} %</strong>
    </div>
    <div style="display: table; margin: 0 auto;">
      <b-button :disabled="warrantyToDownload.length< metadata.totalItem" variant="primary" style="margin-top:20px">
        <download-excel  :data="warrantyToDownload" :fields="filter" name="Warranty.xls" class="download-border">download</download-excel>  
      </b-button>
    </div>
    </b-modal>

    <b-col cols="12">
      <b-row>
        <b-col cols="12" class="mb-2">
          <div class="filter-responsive">
            <div class="input-group">
              <div class="input-group-prepend">
                <b-dropdown style="margin: 0 !important;" id="dropdown-1" :text="selectedFilter" class="m-md-2">
                  <b-dropdown-item @click="dropDownMenuClick" field="fullName">Name</b-dropdown-item>
                  <b-dropdown-item @click="dropDownMenuClick" field="email">Email</b-dropdown-item>
                  <b-dropdown-item @click="dropDownMenuClick" field="phone">Phone</b-dropdown-item>
                  <b-dropdown-item @click="dropDownMenuClick" field="model">Model</b-dropdown-item>
                  <b-dropdown-item @click="dropDownMenuClick" field="serial">Serial</b-dropdown-item>
                  <b-dropdown-item @click="dropDownMenuClick" field="cert">Certificate</b-dropdown-item>
                  <b-dropdown-item @click="dropDownMenuClick" field="barcode">Barcode</b-dropdown-item>
                  <b-dropdown-item @click="dropDownMenuClick" field="postal">Postal</b-dropdown-item>
                </b-dropdown>
              </div>
              <b-form-input v-model="toFilter" placeholder="Filter.."/>
            </div>

            <b-button @click="doFilter" variant="primary">Filter</b-button>
            <b-button @click="clearFilter" variant="primary">Clear</b-button>
          </div>
        </b-col>

        <b-col cols="12">
          <div class="filter-grid my-1">
            <div class="paperless-drop">
              <label>Status</label>
              <b-form-select @change="(value) => fireFiltering('warrantyType', value)" v-model="warrantyType" :options="warrantyOptions"></b-form-select>              
            </div>

            <div class="paperless-drop ">
              <label>Year</label>
              <b-form-select @change="(value) => fireFiltering('year', value)" v-model="year" :options="yearOptions"></b-form-select>
            </div>

            <div class="paperless-drop">
              <label>Month</label>
              <b-form-select :disabled="year === null" @change="(value) => fireFiltering('month', value)" v-model="month" :options="months"></b-form-select>
            </div> 
          </div>
        </b-col>

        <b-col style="margin: 15px 0 0 0" cols="12">
          <!-- <div class="d-flex flex-row mb-1">
            <span class="font-weight-bold">Successful Registrations : {{ statistic.registered }}</span>
            <span class="ml-1 font-weight-bold">Registration Errors : {{ statistic.issue }}</span>
          </div> -->

          <div class="d-flex justify-content-end">
            <b-button class="mr-1" v-if="permission.add" variant="success" to="warranty/new">Add Warranty</b-button>
            <!-- <b-button variant="primary">
            <download-excel :data="warranties" :fields="filter" name="RSP Warranty.xls" class="download-border">download</download-excel>  
            </b-button> -->
            <b-button variant="primary" :disabled="monthYearQueryExist" @click="openDownloadModal">
              Download
            </b-button>
          </div>

          <div class="d-flex justify-content-end mb-1">
            <!-- <a v-if="permission.export" :href="downloadUrl" target="_blank" class="btn btn-primary"><feather-icon size="1x" icon="DownloadIcon" /> Download</a> -->
            <!-- <b-button variant="primary" style="margin-top:20px" @click="promptDownload=true">download
              
            </b-button> -->
            
            
            <!-- <b-button variant="primary" style="margin-top:20px" @click="onexport">download1
              
            </b-button> -->
          </div>
          
          <!-- <b-col cols="2" class="pt-1">
          <b-form-group
            label="Data Per Page"
            label-for="per-page-select"
            label-cols-md="0"
            label-align-sm="left"
            label-size="md"
            class="mb-0"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
            ></b-form-select>
          </b-form-group>
        </b-col> -->

          <b-table
            :items="externalWarranty"
            :fields="fields"
            :filter="search"
            :per-page="perPage"
            :busy.sync="busyLoadingWarranty"
            show-empty
            empty-text="Data Not Found"
            responsive
          >
            <template #cell(actions)="row">
              <!-- <b-button size="sm" @click="detailWarranty(row)" class="mr-1">
                Detail
              </b-button> -->

              <b-button v-if="permission.delete" @click="confirmToDelete(row.item)" variant="danger" :disabled="needDisableDeleteButton(row.item)" size="sm" class="mr-1">
                Delete
              </b-button>
            </template>
            <template #cell(cert)="row">
              <a href="#" @click.prevent="detailWarranty(row)" style="color: #6e6b7b; text-decoration: underline;">
                {{ `${row.item.cert}` }}
              </a>
            </template>

            <template #cell(customer)="row">
              
                {{ `${row.item.nameFirst} ${row.item.nameLast}` }}
              
            </template>

             <template #cell(mergeAddress)="row">
              
                {{ `${row.item.address?row.item.address:''} ${row.item.address2?row.item.address2:''} ${row.item.address3?row.item.address3:''}
                -${row.item.buildingName?row.item.buildingName:''} ` }}
              
            </template>

            <template #cell(createdAt)="row">
              {{ row.item.createdAt?row.item.createdAt:'' | formatDate }}
            </template>

            <template #cell(dealerCode)="row">
              {{ getDealerCode(row.item) }}
            </template>

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="totalData"
            :per-page="perPage"
            @change="onPageChange"
            v-model="reactiveCurrentPage"
            style="float : right;"
          />
        </b-col>
        <!-- Trigger -->
      </b-row>

      <b-modal hide-footer v-model="deleteModal">
        <p>Are you sure you want to delete ?</p>

        <b-button class="mt-2 mr-1" @click="doDelete">Yes</b-button>
        <b-button class="mt-2" @click="deleteModal = false">No</b-button>
      </b-modal>
    </b-col>
  </b-card>
</template>

<script>
import {userAccess,dateFormat} from '@/utils/utils'
import { mapActions, mapState } from 'vuex';
import XLSX from 'xlsx';

export default {
  data() {
    return {
      downPage:0,
      dataDownload:0,
      metadata:{totalPages:0, totalData:0, totalItem:0},
      fields: [
        { key: 'cert', label: 'Certificate', filterable: true, sortable: true },
        { key: 'customer', label: 'Customer Name', filterable: true, sortable: true },
        { key: 'email', label: 'Customer Email' },
        { key: 'installerName', label: 'Installer', filterable: true, sortable: true },
        { key: 'postal', label: 'Postal' },
        { key: "mergeAddress", label: "Address", thStyle: {minWidth: '250px'} },
        { key: 'registerStatus', label: 'Status', filterable: true, sortable: true },
        { key: 'createdAt', label: 'Registration Date' },
        // { key: 'actions', label: 'Actions' }
      ],
      filter: {
        'Certificate'       : "cert",
        'Company Name'      : 'companyName',
        'Customer'          : 'fullName',
        // 'Email'             : 'email',
        'Installation Address' : 'address',
        'Building Type'       : 'buildingType',
        'Model'             : 'itemModel',
        'Serial'             : 'serial',
        'DO No.'             : 'sapDo',
        'DO Date' : {
          field: 'dateDO',
            callback: (value) => {
              let date = value?dateFormat(value):''
              return date;
            }
          },
        'Installation Date' : {
          field: 'installationDate',
            callback: (value) => {
                return dateFormat(value);
            }
          },
        'Contractor Name'   : 'installerName',
        'Dealer Code'       : 'dealerCode',
        'Pcode'             : 'pCode',
        'Project Name'      : 'projectName',
        'Extended Remarks'  : 'remarks',
        'Registration Date'  : {
          field: 'createdAt',
            callback: (value) => {
                return dateFormat(value);
            }
          },
        'Registration Status' : 'registerStatus',
        'flag Expiry'        : 'flagExpiry',
        'General Warranty Expiry' : {
          field: 'generalWarrantyEndDate',
            callback: (value) => {
                return dateFormat(value);
            }
          },
        'Compressor Warranty Expiry' : {
          field: 'compressorWarrantyEndDate',
            callback: (value) => {
                let date = value?dateFormat(value):''
                return date;
            }
          }, 
        'General Additional Period (Month)' : 'generalWarrantyDuration',
        'Compressor Additional Period (Month)' : 'compressorWarrantyDuration',
      },
      promptDownload: false,
      warrantyToDownload:[],
      pageOptions: [5, 10, 20],
      search: '',
      warranties: [],
      currentPage: 1,
      perPage: 10,
      totalData: 1,
      deleteModal: false,
      idToDelete: null,
      statistic: {},
      warrantyType: null,
      warrantyOptions: [
        { value: null, text: 'All' },
        { value: 'Registered', text: 'Registered' },
        { value: 'Cancelled', text: 'Cancelled' },
        // { value: 'Registration Issue', text: 'Registration Issue' },
      ],
      year: null,
      month: null,
      yearOptions: [
        { value: null, text: 'All' },
      ],
      months: [
        { value: null, text: 'All' },
        { value: 1, text: 'January' },
        { value: 2, text: 'February' },
        { value: 3, text: 'March' },
        { value: 4, text: 'April' },
        { value: 5, text: 'May' },
        { value: 6, text: 'June' },
        { value: 7, text: 'July' },
        { value: 8, text: 'August' },
        { value: 9, text: 'September' },
        { value: 10, text: 'October' },
        { value: 11, text: 'November' },
        { value: 12, text: 'December' },
      ],
      downloadUrl: '/api/v1/sales-and-purchase/drcc/download/csv',
      busyLoadingWarranty: true,
      filterItemSize: undefined,
      selectedFilter: 'Name',
      selectedField: 'fullName',
      toFilter: '',
    }
  },
  created() {
    // Setup Store To Save Filtering Information
    this.getFilterPaperless('paperless-list');
    this.currentPage = this.reactiveCurrentPage;
    this.warrantyType = this.reactiveWarrantyType;
    this.year = this.reactiveYear;
    this.month = this.reactiveMonth;

    let now = new Date();
    let year = now.getFullYear();
    
    this.yearOptions.push({ value: year, text: year });

    for (let i = 1; i < 8; i++) {
      this.yearOptions.push({ value: year - i, text: year - i });
    }
    document.title = 'Paperless Warranty | RSP'
  },
  mounted() {
    this.getExternalWarranty({
      year: this.reactiveYear,
      month: this.reactiveMonth,
      status: this.reactiveWarrantyType,
      page:1
    }).then((data) => {
      this.busyLoadingWarranty = false;
      this.processMetadata(data.metadata);
      this.warranties = data.data
      // let changes = data.data.map((x)=> {return {...x,fullName:`${x.nameFirst} ${x.nameLast} `}})
      // this.warranties = changes
      // console.log(this.warranties)
    });

    // this.updateUrl();

    // this.getPaperlessStatistic().then((data) => {
    //   this.statistic = data.data;
    // }).catch((e) => {
    //   console.log(e);
    // });
  },
  destroyed() {
    let field = this.selectedField;
    let filter = this.filterPaperless

    delete(filter[field]);
    this.year = null
    this.month = null

    this.updateFilter({
      name: 'paperless-list',
      data: { ...filter },
    });
  },
  methods: {
    detailWarranty(row) {
      this.updateFilter({
        name: 'paperless-list',
        data: {
          currentPage: this.currentPage,
        }
      });
      this.$router.push(`/warranty/edit/${row.item._id}`);
    },
    needDisableDeleteButton(item) {
      if (item.registerStatus === 'Registered') {
        return true;
      }

      return false;
    },
    confirmToDelete(item) {
      this.idToDelete = item._id;
      this.deleteModal = true;
    },
    doDelete() {
      this.deleteExternalWarranty(this.idToDelete).then(() => {
        this.busyLoadingWarranty = true;
        this.getExternalWarranty().then((data) => {
          this.busyLoadingWarranty = false;
          this.processMetadata(data.metadata);
        });
      }).catch((e) => {
        console.log(e);
      })
    },
    ...mapActions({
      getExternalWarranty: 'warranty/getExternalWarranty',
      deleteExternalWarranty: 'warranty/deleteExternalWarranty',
      getPaperlessStatistic: 'warranty/getPaperlessStatistic',
      getFilterPaperless: 'filter/getFilter',
      updateFilter: 'filter/updateFilter'
    }),
    async openDownloadModal(){
      this.downPage = 0
      this.dataDownload = 0

      this.warrantyToDownload = []
      let dataDownload = this.dataDownload

      this.promptDownload=true;
      let page = this.downPage
      let filter = this.filterPaperless;

      while (dataDownload < this.metadata.totalItem) {

        let dataProcess = []
        let finalData = []
        page++
        this.downPage = page 
       await this.getExternalWarranty({
          year:this.year,
          month: this.month,  
          status: filter.warrantyType,
          page:page,
          entry: 500
        }).then((data) => {
          // this.warrantyToDownload=data.data;
          dataProcess=data.data;
          
          // split into each item 
          for (let i = 0; i < dataProcess.length; i++) {
            const element = dataProcess[i];
            for (let j = 0; j < element.items.length; j++) {
              const el2 = element.items[j]
              let address = element.address?element.address:''
              let address2 = element.address2?element.address2:''
              let address3 =element.address3?element.address3:''
              let buildingName =element.buildingName?element.buildingName:''
              let buildingType =element.buildingType?element.buildingType:''
              
          finalData.push({
            cert : element.cert,
            fullName:element.fullName,
            salutation:element.salutation,
            
            address : `${address} ${address2} ${address3} ${buildingName}`,
            buildingType:buildingType,
            customerType: element.customerType,
            email : element.email,
            phone : element.phone,
            postal : element.postal,
            registerType: element.registerType,
            installerName : element.installerName,
            installationDate: element.installationDate,
            registerStatus : element.registerStatus,
            flagExpiry : el2.flagExpiry,
            purchaseDate: element.purchaseDate,
            companyName : element.companyName,
            
            sapDo: el2.sapDo,
            dateDO: el2.dateDO,
            dealerCode: el2.dealerCode,
            pCode : el2.pcode,
            generalWarrantyDuration : el2.generalWarrantyDuration,
            compressorWarrantyDuration : el2.compressorWarrantyDuration,
            generalWarrantyEndDate : el2.generalWarrantyEndDate,
            compressorWarrantyEndDate:el2.compressorWarrantyEndDate,
            barcode:el2.barcode,
            projectName:el2.projectName,
            itemModel:el2.itemModel,
            serial:el2.serial,
            remarks:el2.remarks,
            createdAt:element.createdAt
            
          })
          
        }   
      }
      // console.log('downloaded warranrty length',this.warrantyToDownload.length);
      this.warrantyToDownload.push(...finalData)
      dataDownload += finalData.length
        this.dataDownload = dataDownload
      
      
        console.log('data downloaded', this.dataDownload)
        console.log('data downloaded 2', this.warrantyToDownload.length)

    });     
  }
    
    
  },
  
  onSelectWarranty(warrantyType) {
      this.busyLoadingWarranty = true;

      if (warrantyType !== null) {
        this.updateFilter({
          name: 'paperless-list',
          data: {
            warrantyType: warrantyType
          }
        })

        this.getExternalWarranty({
          status: warrantyType
        }).then((data) => {
          this.busyLoadingWarranty = false;
          this.processMetadata(data.metadata)
        });

        this.reactiveCurrentPage = 1;
        this.currentPage = 1;
      } else {
        this.updateFilter({
          name: 'paperless-list',
          data: {
            warrantyType: undefined
          }
        });

        this.getExternalWarranty().then((data) => {
          this.busyLoadingWarranty = false;
          this.processMetadata(data.metadata);
        });

        this.reactiveCurrentPage = 1;
        this.currentPage = 1;
      }
    },
    savePaperlessFilter(key, value) {
      let tempFilter = this.filterPaperless;

      if (value === null) {
        tempFilter[key] = undefined;

        if (key === 'year') {
          tempFilter['month'] = undefined;
          this.month = null;
        }
      } else {
        tempFilter[key] = value;
      }
      
      this.updateFilter({
        name: 'paperless-list',
        data: { ...tempFilter }
      });
    },
    fireFiltering(key, value) {
      this.savePaperlessFilter(key, value);
      this.savePaperlessFilter('page', 1);

      let filter = this.filterPaperless;
      // this.updateUrl();

      this.busyLoadingWarranty = true;
      this.getExternalWarranty({ ...filter, status: filter.warrantyType, }).then((data) => {
        this.busyLoadingWarranty = false;
        this.processMetadata(data.metadata);
      });
    },
    // updateUrl() {
    //   let tempData = this.filterPaperless;
    //   let query = queryString.stringify({ ...tempData, status: tempData.warrantyType });
    //   this.downloadUrl = `/api/v1/sales-and-purchase/drcc/download/csv?${query}`
    // },
    getDealerCode(data) {
      if (data.items.length > 0) {
        return data.items[0].dealerCode;
      }

      return undefined;
    },
    frontendFiltered(data) {
      this.busyLoadingWarranty = false;
      this.filterItemSize = data.length;
    },
    onSearch(data) {
      this.busyLoadingWarranty = true;
    },
    dropDownMenuClick(event) {
      let tempFilter = this.filterPaperless;
      let localField = this.selectedField;

      delete(tempFilter[localField]);

      this.updateFilter({
        name: 'paperless-list',
        data: { ...tempFilter }
      });

      this.selectedFilter = event.target.text;
      this.selectedField = event.target.getAttribute('field');
    },
    doFilter() {
      this.savePaperlessFilter(this.selectedField, this.toFilter);
      this.savePaperlessFilter('page', 1);

      let filter = this.filterPaperless;
      // this.updateUrl();

      this.busyLoadingWarranty = true;
      this.getExternalWarranty({ ...filter, status: filter.warrantyType, page:1 }).then((data) => {
        this.busyLoadingWarranty = false;
        this.processMetadata(data.metadata);
      });
    },
    clearFilter() {
      let tempFilter = this.filterPaperless;
      let localField = this.selectedField;

      this.selectedField = 'model';
      this.selectedFilter = 'Model';
      this.toFilter = undefined;

      // console.log(localField)

      delete(tempFilter[localField]);

      this.updateFilter({
        name: 'paperless-list',
        data: { ...tempFilter }
      });

      this.getExternalWarranty({ ...tempFilter, status: tempFilter.warrantyType }).then((data) => {
        this.busyLoadingWarranty = false;
        this.processMetadata(data.metadata);
      });
    },
    onPageChange(page) {
      this.savePaperlessFilter('page', page);

      let filter = this.filterPaperless;
      // console.log({filter})
      // this.updateUrl();

      this.busyLoadingWarranty = true;
      this.getExternalWarranty({ ...filter, status: filter.warrantyType }).then((data) => {
        this.busyLoadingWarranty = false;
        this.processMetadata(data.metadata)
      });
    },
    processMetadata(metadata) {
      this.totalData = metadata.totalData;
      this.metadata.totalPages = metadata.totalPages
      this.metadata.totalData = metadata.totalData
      this.metadata.totalItem = metadata.totalItem
    }
  },
  computed: {
    permission() {
      return userAccess("Paperless Warranty", "warranty_menu");
    },
    ...mapState({
      externalWarranty: (state) => {
        return state.warranty.externalWarranty
      },
      filterPaperless: (state) => {
        if (state.filter.filterContainer['paperless-list'] !== undefined) {
          let paperlessFilter = state.filter.filterContainer['paperless-list'];
          return paperlessFilter;
        }

        return {};
      }
    }),
    progressDownload () {
        let downloaded = this.warrantyToDownload.length
        let dataToDownload = this.metadata.totalItem
        return Math.ceil((downloaded/dataToDownload)*(100))
      },
    monthYearQueryExist () {
      if(this.month == null){
        return true
      }
      if(this.year == null){
        return true
      }
      return false
    },
    reactiveCurrentPage: {
      get() {
        if (this.filterPaperless.currentPage === undefined) {
          return 1;
        }

        return this.filterPaperless.currentPage;
      },
      set(newVal) {
        this.updateFilter({
          name: 'paperless-list',
          data: {
            currentPage: newVal
          }
        });

        this.currentPage = newVal;
      }
    },
    reactiveWarrantyType() {
      if (this.filterPaperless.warrantyType !== undefined) {
        return this.filterPaperless.warrantyType;
      }

      return null;
    },
    reactiveYear() {
      if (this.filterPaperless.year !== undefined) {
        return this.filterPaperless.year;
      }

      return null;
    },
    reactiveMonth() {
      if (this.filterPaperless.month !== undefined) {
        return this.filterPaperless.month;
      }

      return null;
    },
  }
}
</script>

<style scoped>
.paperless-drop {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.paperless-drop label {
  font-weight: bold;
  text-align: left;
}

.filter-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  /* grid-template-columns: 20%; */
  column-gap: 10px;
}

@media only screen and (max-width: 767px) {
  .paperless-drop {
    width: 100%;
  }

  .filter-grid {
    display: flex;
    flex-direction: column;
  }
}

.filter-responsive {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.filter-responsive button {
    margin: 0 0 0 5px;
  }

@media screen and (max-width: 480px) {
  .filter-responsive {
    flex-direction: column;
    justify-content: none;
  }

  .filter-responsive button {
    margin: 5px 0 0 0;
    width: 100px;
  }
}
</style>
